/*breakpoint*/
.card-body.w-70 {
  width: 100%;
  margin: auto;
  padding: 0.25rem; }
  @media (min-width: 768px) {
    .card-body.w-70 {
      width: 90%; } }

label {
  font-size: 11pt; }

span.text-muted {
  font-size: 12px;
  font-weight: 400;
  font-style: italic; }

.UploadButton .input-upload .x-btn {
  border: 1px solid #4f4f4f;
  line-height: 20px;
  border-radius: 15px;
  width: 20px;
  display: inline-block;
  text-align: center;
  color: #4f4f4f;
  margin-left: 1rem;
  padding-right: 2px; }

.UploadButton p {
  display: none; }

.ck-editor__editable {
  min-height: 200px; }
