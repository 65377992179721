/*breakpoint*/
.EmailTemplate h5 {
  font-size: 1.1rem; }

.EmailTemplate__Modal {
  display: flex;
  align-items: center;
  justify-content: center; }
  .EmailTemplate__Modal > .main {
    background: #f9f9f9;
    padding: 0 2em 1em 2em;
    border-radius: 5px;
    font-size: 0.8rem;
    box-shadow: 3px 4px 5px 0px #505050; }
    .EmailTemplate__Modal > .main .title {
      font-size: 0.9rem;
      font-weight: 600; }
    .EmailTemplate__Modal > .main .ck-editor__editable {
      min-height: 9rem; }

.card-flat main {
  padding: 15px; }
  @media (min-width: 768px) {
    .card-flat main {
      padding: 40px 100px 30px 100px; } }
