/*breakpoint*/
.gallery-image-single {
  width: 100px;
  height: 100px;
  cursor: pointer;
  margin: 0px auto;
  display: block;
  margin-top: 5px;
  padding: 5px; }

.gallery-image-single:hover {
  opacity: 0.5; }

.gallery-image-container {
  padding: 0px 15px;
  margin: 0px auto; }
  @media (min-width: 768px) {
    .gallery-image-container {
      padding: 0px 50px; } }

.selected-image {
  border: 3px solid #40bcb4; }

.gallery-image-vessel {
  height: 320px;
  overflow-y: scroll;
  max-height: 320px;
  min-height: 320px;
  margin-bottom: 12px;
  border-bottom: 2px solid #f5f5f5;
  border-top: 2px solid #f5f5f5;
  border-left: 2px solid #f5f5f5; }

.no-data-image {
  margin-left: 20px; }

#style-6::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5; }

#style-6::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5; }

#style-6::-webkit-scrollbar-thumb {
  background-color: #ff8078;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent); }

.modal-gallery .form-group h5 {
  display: none; }

.container-right > .row-m {
  border: 1px solid #e8e9f1; }

.container-right .item-image .img-container-custom {
  max-width: 100%;
  width: 250px;
  height: auto;
  max-height: 100%;
  overflow: hidden;
  position: relative; }
  .container-right .item-image .img-container-custom::after {
    content: "";
    display: block;
    padding-bottom: 100%; }
  .container-right .item-image .img-container-custom img {
    position: absolute;
    left: -1000%;
    right: -1000%;
    top: -1000%;
    bottom: -1000%;
    margin: auto;
    max-height: 200%;
    max-width: 200%;
    z-index: 0; }
    .container-right .item-image .img-container-custom img.selected-image {
      border: 2px blue solid; }

.container-right .item-image span {
  display: block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  padding-top: 10px;
  text-align: center;
  letter-spacing: 0.005em;
  word-break: break-all;
  color: #bdbdbd; }
