.f-text-container {
  color: #828282; }
  .f-text-container h3 {
    font-weight: 500;
    color: #4F4F4F; }

.bg-container {
  background: linear-gradient(275.02deg, rgba(237, 91, 40, 0) 51%, rgba(237, 91, 40, 0.066) 73.58%);
  height: fit-content;
  padding: 5% 1%; }
  .bg-container .container {
    transform: scale(0.8) translateY(-5%); }

.bg-patterns {
  display: inline-block; }
  .bg-patterns .p-container {
    top: 400px;
    right: -50px;
    bottom: unset;
    width: 100px;
    height: 100px;
    position: absolute; }
    .bg-patterns .p-container img {
      position: absolute;
      width: 100%;
      height: 100%; }
    .bg-patterns .p-container .bg-pattern {
      position: absolute;
      background: rgba(255, 228, 219, 0.5);
      width: 100%;
      height: 100%;
      top: 5px;
      left: 5px; }

.bg-patterns-left {
  display: inline-block; }
  .bg-patterns-left .p-container-left {
    top: 0px;
    left: -50px;
    right: -50px;
    bottom: unset;
    width: 100px;
    height: 100px;
    position: absolute; }
    .bg-patterns-left .p-container-left img {
      position: absolute;
      width: 100%;
      height: 100%; }
    .bg-patterns-left .p-container-left .bg-pattern-left {
      position: absolute;
      background: rgba(255, 228, 219, 0.5);
      width: 100%;
      height: 100%;
      top: 5px;
      left: 5px; }
