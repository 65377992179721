#nav-certifivcates .nav-item {
    flex: 0 0 20%;
    max-width: 20%;
    padding: 0 5px;
}
#nav-certifivcates .nav-item .my-btn {
    width: 100%;
}

@media screen and (max-width: 767px) {
    #nav-certifivcates .nav-item .my-btn {
        width: 100%;
        padding: 5px;
        border-radius: 20px;
    }
}

@media screen and (max-width: 575px) {
    /* .wrapp-nav-certivicates {
        display: inline-block;
        width: 100%;
        overflow: scroll;
    }
    #nav-certifivcates {
        width: 640px;
    } */

    #nav-certifivcates {
        justify-content: center;
    }
    #nav-certifivcates .nav-item {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        margin-bottom: 10px;
    }
    #nav-certifivcates .nav-item .my-btn {
        min-height: 55px;
    }

}