.custom_menu > div > div > div > button > span > span > span > span {
  font-size: 12px !important; }

.custom_menu > div > div > div > a > span > span > span {
  font-size: 12px !important; }

.custom_menu > div > div > div > button > span > span > span {
  padding: 0 0.5rem; }

.custom_menu > div > div > div > a > span > span {
  padding: 0 0.5rem;
  font-weight: 600; }

.custom_menu > div > div > div > li > span > span > span,
.custom_menu > div > div > div > button > span > span > span > span,
.custom_menu > div > div > div > div > a > span > span > span {
  font-weight: 600; }
