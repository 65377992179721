.add__promo .react-datepicker-wrapper {
  width: 100%; }
  .add__promo .react-datepicker-wrapper > div {
    width: 100%; }

.add__promo .react-datepicker {
  position: absolute;
  z-index: 99; }

.product-select [class*='menu'] {
  z-index: 2; }
