/*breakpoint*/
.AddFund .f-input-number::placeholder {
  font-size: small;
  padding: 0.5rem;
  color: #a8a8a8; }

textarea {
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
  height: 35px; }
  textarea:focus {
    border: 0.5px solid #a2a2a2; }

.amount-input {
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
  height: 35px; }
  @media (min-width: 576px) {
    .amount-input {
      width: 150px !important;
      padding: 5px; } }
  .amount-input:focus {
    border: 0.5px solid #a2a2a2; }

.bank-item .card {
  cursor: pointer; }

.bank-logo {
  max-width: 100%;
  max-height: 30px; }
