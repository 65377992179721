/*breakpoint*/
.tld-list .custom-checkbox {
  display: inline-block;
  min-width: 120px; }

.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0; }

.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0; }

.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0; }

.slide section * {
  transition: all 0.3s ease; }

.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0; }

.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0; }

.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0; }

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
  opacity: 1; }

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s; }

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s; }

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s; }

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s; }

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s; }

.slide.animateOut h1 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s; }

.slide.animateOut p {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s; }

.slide.animateOut section span {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }

.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s; }

.slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden; }

.slider {
  height: 190px;
  background-image: url("/static/image/panelbg/right-top.png");
  background-repeat: no-repeat;
  background-color: #412e8b;
  background-position: 128% -45px; }
  .slider::after {
    content: '';
    position: absolute;
    height: 98px;
    width: 18px;
    background: url("/static/image/panelbg/promo.png") left top no-repeat; }
  .slider::before {
    content: '';
    position: absolute;
    bottom: 0px;
    height: 30px;
    width: 98px;
    background: url("/static/image/panelbg/dot.png") left top no-repeat; }

.slide {
  background-size: cover !important; }

.slide::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0; }

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-position: center center;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  cursor: pointer; }

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
  background-image: url("/static/image/icon/arrow_left.png"); }

.previousButton:hover {
  left: -10px; }

.nextButton {
  right: 0;
  background-image: url("/static/image/icon/arrow_right.png"); }

.nextButton:hover {
  right: -10px; }

.slider-content {
  text-align: center; }

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s; }

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 18px;
  line-height: 1;
  font-family: 'Playfair Display', serif; }
  .slider-content .inner h1 span {
    font-size: 28px;
    color: #ff6d6d; }

.slider-content .inner h2 {
  font-weight: 900;
  margin: 5px auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 20px;
  line-height: 1;
  font-family: 'Playfair Display', serif; }

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 0px;
  max-width: 640px; }
  @media (min-width: 768px) {
    .slider-content .inner p {
      margin: 20px auto 30px; } }

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px; }

.slider-content section span {
  color: #ffffff; }

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px; }

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle; }

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block; }

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 20px; } }

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 20px; } }
