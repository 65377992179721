.TextInput {
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 1em; }
  .TextInput::placeholder {
    color: #BDBDBD;
    font-style: italic;
    font-size: .8em; }
