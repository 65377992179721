/*breakpoint*/
@media (min-width: 576px) {
  .card-wrapper {
    margin-top: -50px;
    margin-left: -45px; } }

.btn-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 22px; }

.card-body {
  position: relative; }
  @media (min-width: 768px) {
    .card-body {
      position: initial; } }

.card-body.w-70 {
  width: 100%;
  margin: 0; }

label {
  font-size: 11pt; }

span.text-muted {
  font-size: 12px;
  font-weight: 400;
  font-style: italic; }

@media (min-width: 576px) {
  .kurs-dropdown {
    width: 460px !important; } }

.price-summary {
  color: #1CBB8C;
  font-weight: bold;
  margin: 10px 0 0; }
