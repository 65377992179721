/*breakpoint*/
.f-hover-thumbnail-xahhl {
  width: 75px;
  height: 75px;
  border: 1px solid #c5c5c5;
  box-shadow: 1px 1px 5px #cccccc;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 1s; }

.f-hover-thumbnail-xahhl:hover {
  transform: scale(1.2); }

.input-label-search {
  top: -10px !important;
  left: 10px !important;
  color: #a2a2a2 !important; }

.status-search > div > div {
  padding: 12px 13px; }

.colored-border {
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
  width: 400px !important; }
  @media (min-width: 576px) {
    .colored-border {
      margin: 0 20px; } }
  .colored-border:focus {
    border: 0.5px solid #a2a2a2; }

.drop-sizing {
  width: 400px; }
  @media (min-width: 576px) {
    .drop-sizing {
      width: 500px; } }
  @media (min-width: 768px) {
    .drop-sizing {
      margin-top: 10px; } }
