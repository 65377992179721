.abuse-page textarea {
  width: 100%;
  padding: 10px; }

.abuse-page .textwrapper {
  margin: 5px 0;
  padding: 3px; }

.abuse-page h5 {
  color: #343a40;
  font-size: 1em; }
