.DetailItem {
  width: 100%;
  display: flex;
  margin-bottom: 1rem; }
  .DetailItem .card {
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin: 0;
    border-radius: 5px;
    box-shadow: 3px 5px 4px #eceaea; }
    .DetailItem .card .header {
      background: rgba(0, 0, 0, 0.03);
      color: #4f4f4f;
      display: flex;
      align-items: center;
      padding: .7rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
      .DetailItem .card .header .user {
        flex: 1;
        margin-left: 1rem; }
    .DetailItem .card .content {
      padding: 1rem; }
      .DetailItem .card .content p {
        text-align: justify;
        font-size: 13px;
        line-height: 1.5rem; }
      .DetailItem .card .content img {
        max-width: 100%;
        max-height: 100%; }

.MainContent {
  max-height: 50vh;
  overflow-y: auto;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  margin: 1rem 0; }

.StickyBar {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .StickyBar .status {
    display: flex;
    align-items: center;
    justify-content: center; }
