/*breakpoint*/
.deletion-input-search {
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
  width: 400px !important; }
  @media (min-width: 576px) {
    .deletion-input-search {
      width: 700px !important; } }
  .deletion-input-search:focus {
    border: 0.5px solid #a2a2a2; }
