.transfer-internal-container {
  z-index: 1;
  position: relative;
  color: #4f4f4f;
  font-size: 14px; }

.transfer-internal-title {
  font-size: 18px;
  font-weight: bold; }

.transfer-internal-sub-title {
  font-weight: bold; }

.transfer-internal-order-list {
  padding-left: 16px; }

.transfer-internal-text-italic {
  font-style: italic; }
