.custom-table {
  width: 100%;
  background: #ff7f5c;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(224, 224, 224, 0.5);
  color: white;
  border-collapse: collapse; }
  .custom-table,
  .custom-table th,
  .custom-table td {
    border: 1px solid #fff; }
  .custom-table th {
    font-weight: 400;
    padding: 1em; }
  .custom-table tr {
    vertical-align: top; }
    .custom-table tr td {
      padding: 1em; }
    .custom-table tr td:not(:first-child) {
      background: white;
      color: #a8a8a8;
      border: 1px solid #a8a8a8; }
