/*breakpoint*/
.drop-premium-search {
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
  width: 200px !important; }
  @media (min-width: 576px) {
    .drop-premium-search {
      width: 130vh !important;
      margin: 0 4px; } }
  .drop-premium-search:focus {
    border: 0.5px solid #a2a2a2; }
