.card-body.w-70 {
  width: 90%;
  margin: auto; }

label {
  font-size: 11pt; }

span.text-muted {
  font-size: 12px;
  font-weight: 400;
  font-style: italic; }
