/*breakpoint*/
.action-btn button {
  float: left; }

.confirm-input-page {
  width: 400px !important; }
  @media (min-width: 768px) {
    .confirm-input-page {
      width: 200px !important;
      margin: 0 10px; } }

.invoice-act-con:hover {
  background-color: #f1f5f7; }
