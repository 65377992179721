/*breakpoint*/
.List-Contact .no-class {
  width: 100%; }

.List-Contact .MuiExpansionPanelDetails-root {
  padding: 0;
  width: 100%;
  margin-bottom: 2em; }

.List-Contact .fix-button {
  border-radius: 30px !important;
  padding: 0.7em 1.5em !important; }

.input-list-contact {
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
  width: 400px !important; }
  @media (min-width: 576px) {
    .input-list-contact {
      width: 150px !important;
      margin: 0 4px; } }
  .input-list-contact:focus {
    border: 0.5px solid #a2a2a2; }
