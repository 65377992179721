.f-ButtonGroup {
  display: inline-block;
  border: 0.75px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: small; }
  .f-ButtonGroup > button {
    background: #F9F9F9;
    border: none;
    color: #BDBDBD;
    padding: 0.5em 1em; }
    .f-ButtonGroup > button.active {
      background: #FFFFFF;
      border: 0.75px solid #ED5B28;
      color: #ED5B28;
      box-shadow: 0px 4px 10px rgba(224, 226, 237, 0.5); }
      .f-ButtonGroup > button.active--left {
        border-radius: 5px 0px 0px 5px; }
      .f-ButtonGroup > button.active--center {
        border-radius: 0; }
      .f-ButtonGroup > button.active--right {
        border-radius: 0px 5px 5px 0px; }
    .f-ButtonGroup > button:focus {
      outline: none; }
