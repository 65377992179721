.UploadDocument {
  font-size: 13px; }
  .UploadDocument td {
    border-left: 0.75px solid #E0E2ED;
    border-right: 0.75px solid #E0E2ED;
    padding: .5em; }
    .UploadDocument td:first-child {
      padding-right: 3em;
      padding-left: 2em; }
    .UploadDocument td:last-child {
      text-align: left; }
      .UploadDocument td:last-child ul {
        color: #828282;
        list-style-image: url("/static/image/icon/checkmark.png");
        padding-left: 2em;
        padding-right: 7em; }
  .UploadDocument tr:last-child td {
    border-bottom: 0.75px solid #E0E2ED; }

.UploadDocument--upload {
  font-size: 10px;
  display: flex;
  background: #172029;
  color: white;
  flex-direction: column;
  align-items: center;
  padding: 4em 1em; }
  .UploadDocument--upload h5 {
    line-height: normal;
    font-size: 18px;
    text-align: justify;
    letter-spacing: 0.005em;
    font-weight: 600; }
  .UploadDocument--upload p {
    margin: 3em 2em; }
