.f-CardFlat {
  border: 0.75px solid #E0E2ED;
  box-sizing: border-box;
  border-radius: 5px; }
  .f-CardFlat > h5 {
    border-bottom: 0.75px solid #E0E2ED;
    padding: 1em 0 1em 1em;
    color: #4F4F4F;
    font-family: "Montserrat";
    line-height: normal;
    font-size: 14px;
    font-weight: 600; }
  .f-CardFlat > main {
    min-height: 50px; }
