/*breakpoint*/
.Pagination-new {
  display: inline-block;
  width: 100%;
  text-align: center; }
  @media (min-width: 768px) {
    .Pagination-new {
      display: inline-flex; } }
  .Pagination-new .list {
    width: fit-content;
    display: inline-block; }
