.agreement-action {
  max-height: 500px;
  overflow-y: scroll;
  margin: 3em 1em;
  text-align: justify; }

@media (min-width: 1200px) {
  .agreement-action {
    max-height: 350px; } }

@media (min-width: 1400px) {
  .agreement-action {
    max-height: 500px; } }
