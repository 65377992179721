/*color name*/
/*color gradient background*/
.footer {
  font-size: 14px !important;
  color: #FFF;
  background-color: #25262f; }
  .footer img {
    width: 100px;
    height: 30px; }

.footer-1 {
  background: #25262f;
  color: #c1c1c1; }
  .footer-1 h5 {
    text-transform: uppercase; }
  .footer-1 a {
    color: #c1c1c1; }
  .footer-1 ul {
    padding-left: 0; }
    .footer-1 ul li {
      color: #c1c1c1; }
    .footer-1 ul li a:hover {
      color: #ff9d78 !important; }
  .footer-1 .__none {
    list-style-type: none; }
  .footer-1 .footer-menu {
    border-bottom: 1px solid #c1c1c1;
    display: flex;
    justify-content: space-between;
    width: 90%; }
    @media (min-width: 1500px) {
      .footer-1 .footer-menu {
        width: 60%; } }
    @media (max-width: 1025px) {
      .footer-1 .footer-menu {
        width: 80%; } }
    .footer-1 .footer-menu h5 {
      color: white; }
    .footer-1 .footer-menu ul {
      -moz-column-count: 2;
      -moz-column-gap: 0px;
      -webkit-column-count: 2;
      -webkit-column-gap: 0px;
      column-count: 2;
      column-gap: 0px;
      width: 100%;
      padding-left: 0; }
    .footer-1 .footer-menu ul:last-child {
      width: 90%; }
    .footer-1 .footer-menu li {
      list-style: none;
      line-height: 3em; }
    .footer-1 .footer-menu li a:hover {
      text-decoration: none; }
