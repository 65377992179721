.input-domain {
  margin: 0 20%; }
  .input-domain input {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px 21px;
    width: 100%;
    color: #CBCBCB;
    margin-bottom: 20px; }
  .input-domain input[type=checkbox] {
    display: inline-block;
    text-align: left;
    width: 10%; }
  .input-domain ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    line-height: normal;
    font-size: 14px;
    text-align: justify;
    letter-spacing: 0.005em;
    color: #BDBDBD; }
  .input-domain button {
    margin-right: 20px; }
