/*breakpoint*/
.SearchSelect2 {
  display: inline-block;
  position: relative;
  width: 100%; }
  .SearchSelect2 .select2-container {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 5px 5px 0px 0px;
    max-width: 100%;
    width: 100%; }
    @media (min-width: 768px) {
      .SearchSelect2 .select2-container {
        min-width: 300px; } }
  .SearchSelect2 .select2-selection {
    min-height: 2.5rem;
    padding: 0.3rem; }
  .SearchSelect2 .select2-selection__arrow {
    display: none; }
  .SearchSelect2 .select2-selection--single {
    text-align: left;
    border: 1px solid #ced4da !important; }
