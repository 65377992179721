/* Customize the label (the container) */
.f-checkbox {
  display: inline;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 33px; }
  .f-checkbox input {
    position: absolute;
    opacity: 1;
    cursor: pointer;
    height: 0;
    width: 0; }
    .f-checkbox input:checked ~ .checkmark:after {
      visibility: visible; }
    .f-checkbox input:checked ~ .checkmark {
      border: 1px solid #5D81EC; }
  .f-checkbox .checkmark {
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px; }
    .f-checkbox .checkmark::after {
      content: "";
      position: absolute;
      visibility: hidden;
      left: 4px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid #5D81EC;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
