@font-face {
  font-family: "Montserrat";
  src: url("/static/fonts/Vera/Vera.ttf"); }

.TableGrid {
  font-family: "Montserrat";
  font-size: small; }
  .TableGrid .f-row {
    display: grid;
    padding-top: 1em;
    padding-bottom: 1em; }
    .TableGrid .f-row.active {
      background: #EEF1FB; }
  .TableGrid .f-header {
    border-top: 0.75px solid #E0E2ED;
    border-bottom: 0.75px solid #E0E2ED;
    color: #A8A8A8;
    padding-left: 2em;
    font-weight: 600; }
  .TableGrid .f-coloum {
    overflow-wrap: break-word; }
  .TableGrid .f-body {
    background: #FFFFFF;
    border: 1px solid #E8E9F1;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(224, 224, 224, 0.5);
    border-radius: 3px;
    color: #828282;
    padding-left: 1em;
    margin-top: 0.5em;
    margin-left: 1em;
    margin-right: 1em; }
    .TableGrid .f-body input {
      vertical-align: middle;
      margin-right: 1em; }
