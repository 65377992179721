.CardWithHeader .header, .CardWithHeader input::placeholder {
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;
  font-size: 14px;
  color: #4F4F4F; }

.CardWithHeader {
  border-radius: 5px !important;
  border: 0.75px solid #e0e2ed;
  box-sizing: border-box; }

.CardWithHeader {
  overflow: initial !important; }
  .CardWithHeader .header {
    font-weight: bold;
    background: #f7f7f7;
    padding: 1rem;
    border-bottom: 0.75px solid #e0e2ed;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .CardWithHeader .search {
    position: relative;
    border-radius: 5px;
    background: white;
    background: white;
    border: 1px solid;
    padding-left: 2em; }
  .CardWithHeader .searchIcon {
    left: 11px;
    width: 7px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center; }
  .CardWithHeader .inputRoot {
    color: inherit; }
  .CardWithHeader .inputInput {
    padding: 1em, 1em, 1em, 7em;
    transition: width;
    width: 100%; }
