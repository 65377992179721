.panel-white .svg-inline--fa {
  margin-right: 10px; }

.panel-white .container .row {
  margin-top: 20px; }

.panel-white .container p {
  padding: 10px 0; }

.panel-white .container input {
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px 21px;
  width: 100%;
  color: #CBCBCB; }

.panel-white .container ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #CBCBCB; }

.panel-white .container select {
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px 21px;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #A8A8A8; }

.panel-white .container select.round {
  background-image: linear-gradient(45deg, transparent 50%, #ED5B28 50%), linear-gradient(135deg, #ED5B28 50%, transparent 50%), radial-gradient(#fff 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat; }

.panel-white .container .price {
  color: #1B6AD9;
  font-style: italic; }

.panel-white .container .italic input {
  font-style: italic; }

.panel-white .container .transfer {
  margin: 0 3% 0 15%; }
