.card-body.w-70 {
  width: 90%;
  margin: auto; }

label {
  font-size: 11pt; }

span.text-muted {
  font-size: 12px;
  font-weight: 400;
  font-style: italic; }

.UploadButton .input-upload .x-btn {
  border: 1px solid #4f4f4f;
  line-height: 20px;
  border-radius: 15px;
  width: 20px;
  display: inline-block;
  text-align: center;
  color: #4f4f4f;
  margin-left: 1rem;
  padding-right: 2px; }

.ck-editor__editable {
  min-height: 200px; }

.ticket-date {
  float: right; }

.ticket-ml {
  margin-left: 20rem !important; }

.ticket-mr {
  margin-right: 20rem !important; }
