.Pagination {
  font-size: small; }
  .Pagination button {
    background: #ffffff;
    border-color: #165fd2;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 3px;
    color: #165fd2;
    padding: 0.3em 1.5em;
    cursor: pointer;
    user-select: none; }
  .Pagination button:disabled {
    background: #ffffff;
    border-color: #e8e9f1;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 3px;
    color: #a8a8a8;
    padding: 0.3em 1.5em;
    cursor: pointer;
    user-select: none;
    cursor: not-allowed; }
  .Pagination ul {
    display: inline;
    list-style: none;
    padding: 0; }
    .Pagination ul li {
      display: inline;
      background: #ffffff;
      border: 1px solid #e8e9f1;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 0.4em 1em;
      margin: 0.3em;
      color: #a8a8a8;
      cursor: pointer;
      user-select: none; }
    .Pagination ul .active-item {
      color: white;
      background: #165fd2; }
  .Pagination .last-page {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 3px;
    color: #165fd2;
    padding: 0.3em 1.5em;
    cursor: pointer;
    user-select: none;
    font-size: 0.9em; }

.Pagination-new {
  display: inline-flex;
  list-style: none;
  font-size: small;
  padding-left: 0px; }
  .Pagination-new .list {
    background: #ffffff;
    border: 1px solid #e8e9f1;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0.4em 1em;
    margin: 0.3em;
    cursor: pointer;
    user-select: none; }
    .Pagination-new .list a {
      color: #a8a8a8;
      text-decoration: none; }
  .Pagination-new .prev-next {
    background: #ffffff;
    border-color: #165fd2;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0.3em 1.5em;
    cursor: pointer;
    user-select: none; }
    .Pagination-new .prev-next a {
      color: #165fd2; }
  .Pagination-new .disabled {
    background: #ffffff;
    border-color: #e8e9f1;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0.3em 1.5em;
    user-select: none;
    cursor: not-allowed; }
    .Pagination-new .disabled a {
      cursor: not-allowed;
      color: #a8a8a8; }
  .Pagination-new .active {
    background: #165fd2; }
    .Pagination-new .active a {
      color: white; }
