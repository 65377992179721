/*breakpoint*/
.ListDomain .MuiExpansionPanelDetails-root {
  padding-left: 0;
  padding-right: 0; }

.ListDomain .MuiButtonBase-root.MuiExpansionPanelSummary-root {
  padding: 0; }

.ListDomain .f-title.my-2.f-size-small {
  font-size: 18px; }

.ListDomain .rt-td span {
  font-weight: normal; }

.service_name {
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
  width: 400px !important; }
  .service_name:focus {
    border: 0.5px solid #a2a2a2; }

.place-label {
  top: -10px !important;
  left: 15px !important;
  color: #a2a2a2 !important;
  font-weight: 400; }

.cs-select {
  height: 38px;
  width: 400px !important;
  margin: 0 10px; }

.table-card {
  padding: 10px;
  margin-bottom: 20px; }
