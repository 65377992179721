/*breakpoint*/
body {
  background: #f1f5f7 !important; }

.colored-border {
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
  width: 400px !important; }
  @media (min-width: 576px) {
    .colored-border {
      width: 150px !important;
      margin: 0 4px; } }
  .colored-border:focus {
    border: 0.5px solid #a2a2a2; }

.f-button {
  background: white;
  border: none;
  color: #a8a8a8; }

.btn-add-container {
  font-size: 12px;
  display: flex;
  padding: 0px 15px; }
