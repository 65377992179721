/*breakpoint*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Variables
# Normalize
# Typography
	## Headings
	## Copy
# Elements
	## Lists
	## Tables
# Forms
	## Buttons
	## Fields
# Navigation
	## Links
	## Menus
	## Next & Previous
# Accessibility
# Alignments
# Clearings
# Layout
# Widgets
# Content
	## Archives
	## Posts and pages
	## Comments
# Blocks
# Media
	## Captions
	## Galleries


/*color name*/
.f-title-bold, .f-title-blue-bold, .f-title, .f-title-blue, .PreviewFile {
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;
  font-size: 14px;
  color: #4f4f4f; }

.f-title-bold, .f-title-blue-bold {
  font-weight: bold; }

.f-title {
  font-weight: normal; }

.f-title-blue {
  color: #2988ec; }

.f-title-blue-bold {
  color: #2988ec !important; }

.f-text-size-small-bold {
  font-size: 0.85rem;
  font-weight: 600; }

.f-size-small {
  font-size: 0.75rem; }

.f-button {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #e8e9f1;
  color: #a8a8a8;
  padding: 1rem; }
  .f-button--active {
    background: #165fd2;
    color: white; }
  .f-button--disable {
    border: 1px solid #e8e9f1; }
  .f-button--blue {
    border: 1px solid #165fd2;
    color: #165fd2; }
  .f-button--red {
    background: #ff7f5c;
    color: white;
    font-weight: 100; }
  .f-button--gray {
    background: #e0e0e0;
    color: #4f4f4f;
    font-weight: 100; }

.f-input--text, .f-input--text--full {
  padding: 0.5rem 0.5rem; }
  .f-input--text::placeholder, .f-input--text--full::placeholder {
    font-family: Montserrat;
    color: #bdbdbd;
    padding: 1rem; }

.f-input--text--full {
  width: 100%; }

.box-bordered, .box-border-shadow, .box-border-input, .f-input--text, .f-input--text--full, .box-border-input--2 {
  background: #ffffff;
  border: 1px solid #e0e2ed;
  box-sizing: border-box;
  border-radius: 3px; }

.box-border-shadow {
  box-shadow: 0px 4px 4px rgba(224, 224, 224, 0.5);
  padding: 10px; }
  @media (min-width: 768px) {
    .box-border-shadow {
      padding: 2rem; } }

.box-border-input, .f-input--text, .f-input--text--full {
  border: 1px solid #e0e2ed; }

.box-border-input--2 {
  border: 1px solid #e0e0e0; }

.f-input-date {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 0.5rem 1rem;
  min-width: 20rem;
  color: #828282; }

.f-middle-vertical {
  display: flex;
  align-items: center; }

.clickable {
  user-select: none;
  cursor: pointer; }

.scrollbar-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  background-color: #f5f5f5; }

.scrollbar-1::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5; }

.scrollbar-1::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.03);
  background-color: #555; }

.f-v-mid {
  vertical-align: -0.2rem; }

/* loading dots */
.f-loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite; }

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0); }
  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0); }
  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0); }
  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black; } }

.card {
  box-shadow: 0px 4px 14px rgba(224, 224, 224, 0.5); }

a .MuiTypography-body1 {
  color: #4f4f4f; }

.PreviewFile {
  width: fit-content; }
  .PreviewFile textarea {
    background: #FFFFFF;
    border: 1px solid #E0E2ED;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    min-height: 100px; }
  .PreviewFile p {
    margin: 0; }
  .PreviewFile img {
    width: 350px;
    margin-right: 5rem; }
  .PreviewFile .btn, .PreviewFile .btn-accept, .PreviewFile .btn-reject {
    border-radius: 25px;
    border: none;
    padding: .7rem 1rem;
    min-width: 130px;
    font-weight: 600;
    cursor: pointer; }
    .PreviewFile .btn-accept {
      color: #219653;
      background: #CAFFE0; }
    .PreviewFile .btn-reject {
      color: #EB5757;
      background: #FFC5C5; }

.CardHeader {
  border-radius: 3px; }
  .CardHeader h5 {
    padding: 1rem;
    background: #F7F7F7;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 1rem;
    border-radius: 3px 3px 0 0; }
  .CardHeader main {
    border-radius: 0 0 3px 3px;
    padding: 1rem;
    background: #FFFFFF; }
