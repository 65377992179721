.main-layout {
  position: relative;
  background-color: #f8f8f8;
  flex: 1; }

.background {
  position: absolute;
  width: 100%; }
  .background__top {
    left: 0;
    top: 0;
    transform: rotate(180deg); }
  .background__bottom {
    left: 0;
    bottom: 0; }
