.registration-img {
  width: 487px;
  height: 350px;
  box-shadow: 0px 10px 15px rgba(130, 130, 130, 0.3);
  border-radius: 5px; }

.registration-container {
  z-index: 1;
  position: relative;
  font-size: 14px; }

.registration-title {
  font-size: 20px;
  font-weight: bold;
  color: #4f4f4f; }

.registration-sub-title {
  font-weight: bold;
  font-size: 16px;
  color: #4f4f4f; }

.registration-order-list {
  padding-left: 16px;
  color: #828282; }
