.custom-control-input:focus~.custom-control-label::before {
	border-color: #1CBB8C !important;
	box-shadow: 0 0 0 0.2rem rgba(255, 47, 69, 0.25) !important;
  }
  
  .custom-control-input:checked~.custom-control-label::before {
	border-color: #1CBB8C !important;
	background-color: #1CBB8C !important;
  }
  
  .custom-control-input:active~.custom-control-label::before {
	background-color: #1CBB8C !important;
	border-color: #1CBB8C !important;
  }
  
  .custom-control-input:focus:not(:checked)~.custom-control-label::before {
	border-color: #1CBB8C !important;
  }
  
  .custom-control-input-green:not(:disabled):active~.custom-control-label::before {
	background-color: #1CBB8C !important;
	border-color: #1CBB8C !important;
  }