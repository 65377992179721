/*color name*/
/*color gradient background*/
.layout-auth > .Card .error-message {
  color: #002144; }

.layout-auth > .Card > form.reset-form input {
  border: 1px solid #ffbaa2; }
  .layout-auth > .Card > form.reset-form input::placeholder {
    color: #ffbaa2; }
  .layout-auth > .Card > form.reset-form input.is-invalid {
    border-color: #dc3545 !important; }

.layout-auth > .Card > form.reset-form a {
  color: #ff817a;
  text-transform: uppercase; }
  .layout-auth > .Card > form.reset-form a:hover {
    text-decoration: none; }

@media (max-width: 768px) {
  .layout-auth > .Card {
    padding: 70px 50px !important; } }

@media (max-width: 576px) {
  .layout-auth > .Card {
    padding: 40px 20px !important; } }
