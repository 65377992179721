.bordered {
  border: 1px solid #eff0f6 !important; }

.suggestion-container {
  background-color: white;
  color: black;
  position: absolute;
  min-width: 215px;
  max-width: 215px;
  top: 51px;
  margin-left: 8px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 9px 24px 0 rgba(0, 0, 0, 0.14); }

.list-suggestion {
  list-style: none;
  padding-left: 8px; }

.list-suggestion:hover {
  background-color: #1c6cdb;
  color: white; }

.suggestion-selected {
  background-color: #1c6cdb;
  color: white; }
