/*color name*/
/*color gradient background*/
/*breakpoint*/
.layout-auth > .Card .error-message {
  color: #002144; }

.layout-auth > .Card > form.reset-form input {
  border: 1px solid #ffbaa2; }
  .layout-auth > .Card > form.reset-form input::placeholder {
    color: #ffbaa2; }
  .layout-auth > .Card > form.reset-form input.is-invalid {
    border-color: #dc3545 !important; }

.layout-auth > .Card > form.reset-form a {
  color: #ff817a;
  text-transform: uppercase; }
  .layout-auth > .Card > form.reset-form a:hover {
    text-decoration: none; }

#__next {
  height: auto; }
  #__next .layout-auth .PatternContainer {
    width: 100%; }
    #__next .layout-auth .PatternContainer .content {
      text-align: center; }
      #__next .layout-auth .PatternContainer .content .Card {
        width: 100%;
        margin: 0px;
        box-shadow: rgba(224, 224, 224, 0.5) 0px 8px 14px;
        z-index: 2; }
        #__next .layout-auth .PatternContainer .content .Card .logo-container {
          text-align: center; }
          @media (min-width: 768px) {
            #__next .layout-auth .PatternContainer .content .Card .logo-container {
              text-align: left; } }
        @media (min-width: 768px) {
          #__next .layout-auth .PatternContainer .content .Card {
            width: 640px;
            padding: 32px; } }
