.f-Table {
  background: #FF807A;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 4px 10px rgba(224, 224, 224, 0.5);
  color: white; }
  .f-Table th {
    font-weight: 400;
    padding: 1em; }
    .f-Table th:first-child {
      border-bottom: 1px solid white;
      border-right: 1px solid white; }
  .f-Table tr td {
    padding: 1em; }
  .f-Table tr td:last-child {
    background: white;
    color: #A8A8A8; }
