/*breakpoint*/
.ticket-guide-img {
  width: 487px;
  height: 350px;
  box-shadow: 0px 10px 15px rgba(130, 130, 130, 0.3);
  border-radius: 5px;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.ticket-guide-title {
  font-weight: bold;
  font-size: 24px; }

.ticket-card {
  background-color: white;
  box-shadow: 0px 9px 10px rgba(224, 224, 224, 0.5);
  border-radius: 5px;
  margin-top: 10px;
  height: auto;
  padding: 15px 0px;
  margin-left: 0px;
  margin-right: 0px; }
  @media (min-width: 768px) {
    .ticket-card {
      margin-right: -15px;
      margin-left: -15px; } }

.ticket-card-number {
  text-align: center;
  background-color: #ffd7d0;
  height: 50px;
  width: 50px;
  border-radius: 30px;
  margin: 0px auto;
  display: block;
  margin-top: calc(50% - 25px); }

.ticket-card-number span {
  font-size: 24px;
  font-weight: bold;
  color: #ed5b28;
  line-height: 50px; }

.ticket-card-content {
  padding-left: 0;
  font-size: 14px;
  padding-top: 10px;
  position: relative;
  height: inherit; }

.ticket-card-content p {
  margin: 0;
  text-align: justify; }
