/*breakpoint*/
.filter-search .MuiExpansionPanelDetails-root {
  padding-left: 0px;
  padding-right: 0px; }

.modal-detail .PatternContainer {
  width: 90%; }
  @media (min-width: 768px) {
    .modal-detail .PatternContainer {
      width: 50%; } }

.invoice-filter {
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
  width: 400px !important; }
  @media (min-width: 576px) {
    .invoice-filter {
      width: 15rem !important;
      margin: 0 4px; } }
  .invoice-filter:focus {
    border: 0.5px solid #a2a2a2; }
