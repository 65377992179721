.PreviewFileustom {
  max-width: 100%; }
  .PreviewFileustom .content {
    display: flex;
    min-height: 100px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-height: 66vh;
    overflow-y: auto; }
  .PreviewFileustom .colored-border {
    border: 1px solid #ced4da;
    border-radius: 5px;
    outline: none; }
    .PreviewFileustom .colored-border:focus {
      border: 0.5px solid #a2a2a2; }
