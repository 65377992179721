/*breakpoint*/
.list-365 {
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
  width: 400px !important; }
  @media (min-width: 576px) {
    .list-365 {
      width: 500px !important;
      margin: 0 4px; } }
  .list-365:focus {
    border: 0.5px solid #a2a2a2; }
