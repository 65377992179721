.domain-policy-img {
  width: 487px;
  height: 350px;
  box-shadow: 0px 10px 15px rgba(130, 130, 130, 0.3);
  border-radius: 5px; }

.domain-policy-container {
  z-index: 1;
  position: relative;
  color: #4f4f4f;
  font-size: 14px; }

.domain-policy-title {
  font-size: 18px;
  font-weight: bold; }

.domain-policy-sub-title {
  font-weight: bold;
  font-size: 16px; }

.domain-policy-order-list {
  padding-left: 16px; }

.domain-policy-text-italic {
  font-style: italic;
  font-weight: bold; }
