@font-face {
  font-family: "Montserrat";
  src: url("/static/fonts/Vera/Vera.ttf"); }

.f-info {
  background: #F1F3FF;
  border-radius: 5px;
  color: #165FD2;
  height: 100%;
  padding: 1em; }

.f-table {
  font-family: "Montserrat";
  line-height: normal;
  font-size: 14px;
  font-weight: 600;
  color: #4F4F4F;
  width: 90%; }
  .f-table tr:nth-child(1) td {
    padding-top: 2em; }
  .f-table th {
    border-bottom: 1px solid #E0E2ED;
    padding-bottom: 0.5em; }
  .f-table td {
    color: #404852;
    font-weight: 400;
    padding: 0.7em 0; }
    .f-table td input {
      background: #FFFFFF;
      border: 0.75px solid #E0E2ED;
      box-sizing: border-box;
      border-radius: 3px;
      height: 2em;
      color: #828282;
      padding: 1em;
      width: 100%; }
    .f-table td:first-child {
      width: 200px; }
    .f-table td:last-child {
      width: 70%; }

.f-buttons {
  display: flex;
  margin-top: 6em;
  justify-content: flex-end; }
