.RegisterDomain .rt-thead.-header {
  min-width: 1020px !important; }

.RegisterDomain .rt-tbody {
  max-height: 42vh;
  min-width: 1020px !important; }
  .RegisterDomain .rt-tbody .rt-td:first-child {
    justify-content: center;
    padding-left: 1rem; }
    .RegisterDomain .rt-tbody .rt-td:first-child > span {
      width: 100%;
      display: flex;
      justify-content: space-between; }
  .RegisterDomain .rt-tbody .rt-td span {
    font-weight: normal; }

.RegisterDomain .content {
  margin-top: 0 !important;
  padding: 5px !important; }

.RegisterDomain .step-2 .TableReactVendor .rt-tr-group:first-child {
  background: rgba(0, 0, 0, 0.03);
  color: #4f4f4f; }

@media screen and (max-width: 1366px) {
  /* Component */
  .Stepper .my-btn {
    font-size: 0.8rem; }
  .Stepper .panel-group .label-text {
    font-size: 0.9rem; }
  .Stepper {
    min-height: 12vh;
    margin-top: 20px; }
  .RegisterDomain .rt-tbody {
    max-height: 35vh !important; }
  .footer {
    display: none; } }

@media screen and (max-width: 600px) {
  .footer {
    display: none; } }

.gallery-image-container .CardWithHeader .header .search {
  margin-left: 15px; }
