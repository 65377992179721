.DomainControl .group--top {
  width: 100%; }
  .DomainControl .group--top p {
    color: #A8A8A8;
    margin: 0;
    font-size: small;
    width: 55%;
    float: right; }

.DomainControl input {
  margin-top: 2em;
  margin-bottom: 4em;
  width: 100%;
  padding-top: 0.4em;
  padding-bottom: 0.4em; }
