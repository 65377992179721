.TitleBar_ {
  width: 100%; }

.list-faq nav > div {
  transition: none; }
  .list-faq nav > div:active, .list-faq nav > div:visited, .list-faq nav > div:hover {
    background-color: transparent; }
    .list-faq nav > div:active span, .list-faq nav > div:visited span, .list-faq nav > div:hover span {
      color: #007bff; }
  .list-faq nav > div .MuiTouchRipple-root {
    display: none; }
