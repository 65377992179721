/*breakpoint*/
.action-btn button {
  float: left; }

.total-data {
  height: 60px;
  line-height: 60px;
  font-size: 12px;
  color: #a59797; }

.no-class .MuiPaper-root .MuiButtonBase-root {
  padding: 0px; }

.no-class .MuiPaper-root .MuiExpansionPanelDetails-root {
  padding-left: 0px;
  padding-right: 0px; }

.deposit-list .rt-table .rt-tbody .rt-tr .rt-td span {
  font-weight: normal;
  font-size: 14px; }

#payment_status fieldset {
  border: 0px transparent solid; }

#date .QDatePicker .react-datepicker-manager,
#date .QDatePicker .react-datepicker-wrapper,
#date .QDatePicker .react-datepicker__input-container {
  width: calc(90% - 23px); }

#date .QDatePicker .react-datepicker__input-container input {
  width: 100%; }

#date .QDatePicker .react-datepicker {
  position: absolute;
  z-index: 99999; }

.PanelLayout-content-3 .Pagination-new {
  display: inline-block !important;
  width: 100%;
  text-align: center; }
  @media (min-width: 768px) {
    .PanelLayout-content-3 .Pagination-new {
      display: inherit !important; } }
  .PanelLayout-content-3 .Pagination-new .list {
    width: fit-content;
    display: inline-block; }

.modal-deposit .PatternContainer {
  width: 90%; }
  @media (min-width: 768px) {
    .modal-deposit .PatternContainer {
      width: 50%; } }

.dep-input {
  width: 400px !important; }
  @media (min-width: 768px) {
    .dep-input {
      width: 200px !important;
      margin: 0 10px; } }
