/*color name*/
/*color gradient background*/
@font-face {
  font-family: "Montserrat";
  src: url("/static/fonts/Vera/Vera.ttf"); }

.Card {
  display: inline-block;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(224, 224, 224, 0.5);
  border-radius: 6px;
  padding: 1em;
  width: 25%;
  text-align: left;
  margin: 1em;
  font-family: "Montserrat";
  font-size: 14px;
  color: #828282; }

.CardShadow {
  background: #FFFFFF;
  box-shadow: 0px 8px 14px rgba(224, 224, 224, 0.5);
  border-radius: 10px;
  color: #4f4f4f; }
  .CardShadow .closeButton {
    position: absolute;
    top: -11px;
    right: -13px;
    border: 1px solid #E0E2ED;
    border-radius: 10em;
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #898989;
    font-weight: 600;
    background: white;
    cursor: pointer; }
