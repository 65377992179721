.input-date {
  margin: 0 5%; }
  .input-date input {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px 21px;
    width: 100%;
    color: #CBCBCB;
    margin-bottom: 20px; }
  .input-date input[type=checkbox] {
    display: inline-block;
    text-align: left;
    width: 10%; }
  .input-date ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    line-height: normal;
    font-size: 14px;
    text-align: justify;
    letter-spacing: 0.005em;
    color: #BDBDBD; }
  .input-date label {
    margin-top: 10px; }
  .input-date button {
    margin-right: 20px; }
