.panel-white {
  display: inline-block;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(224, 224, 224, 0.5);
  border-radius: 6px;
  padding: 1em;
  text-align: left;
  margin: 70px 1em;
  font-family: "Montserrat";
  font-size: 14px;
  width: 100%; }
